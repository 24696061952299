import { render, staticRenderFns } from "./SearchByTags.vue?vue&type=template&id=12649e04&scoped=true"
import script from "./SearchByTags.vue?vue&type=script&lang=js"
export * from "./SearchByTags.vue?vue&type=script&lang=js"
import style0 from "./SearchByTags.vue?vue&type=style&index=0&id=12649e04&prod&scoped=true&lang=css"
import style1 from "./SearchByTags.vue?vue&type=style&index=1&id=12649e04&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12649e04",
  null
  
)

export default component.exports