<template>
  <div class="order-action">
    <v-menu
      top
      offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          block
          color="secondary"
          class="d-flex justify-space-between"
          v-bind="attrs"
          :disabled="isLoading"
          v-on="on">
          <div />
          ตัวเลือก
          <v-icon>
            mdi-chevron-up
          </v-icon>
        </v-btn>
      </template>
      <v-list style="padding: 0 !important;">
        <v-list-item
          v-for="(button, index) in buttons"
          :key="index"
          :style="{
            backgroundColor: `${button.bgColor || '#fff'} !important`,
            color: `${button.color || '#000'} !important`
          }"
          @click="switchFunction(button.function, orderDetail)">
          <v-list-item-title>
            {{ button.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="dialogTrackingNumber"
      width="480"
      persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Tracking Number</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="trackingNumber"
                  outlined />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialogTrackingNumber()">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loadingTrackingNumber"
            @click="addTrackingNo(orderDetail, trackingNumber)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRegenOrder"
      width="350"
      persistent>
      <v-card>
        <v-card-title>
          <span class="headline">New Order</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                ORDER ID : {{ regenOrderResult ? regenOrderResult.orderId : '' }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            text
            @click="copyOrderId(regenOrderResult.orderId)">
            Copy to Clipboard
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialogRegenOrder()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="summaryDialog"
      transition="dialog-top-transition"
      max-width="600">
      <v-card class="pt-2">
        <v-card-text>
          <div class="font-weight-bold">
            สรุปการสั่งซื้อ
          </div>
          <div class="mt-2">
            <div
              v-for="(sku, index) in summaryDialogText.skus"
              :key="`sku-dialog-text-${index}`"
              class="text-capitalize">
              {{ sku.name }} : {{ sizeSkuFormatter(sku.size) }},{{ sku.color }} ({{ sku.amount }}) x {{ sku.price | showFullPriceFormat() }}
            </div>
          </div>
          <div class="mt-2">
            ส่วนลด {{ showFullPriceFormat(Math.ceil(summaryDialogText.discount)) }}
          </div>
          <div class="text-uppercase">
            ค่าจัดส่ง ({{ summaryDialogText.shipping.method || ' - ' }}) {{ summaryDialogText.shipping.fee | showFullPriceFormat() }}
          </div>
          <div>
            รวม {{ Math.ceil(summaryDialogText.net) | showFullPriceFormat() }}
          </div>
          <div class="mt-2">
            ตรวจสอบรายละเอียดการสั่ง วิธีการชำระเงิน ยืนยันการชำระและที่อยู่ในการจัดส่งได้ที่นี่เลย
            <span>
              <a
                :href="`${billUrl}/th/${summaryDialogText.billUrl}`"
                target="_blank">
                {{ billUrl }}/th/{{ summaryDialogText.billUrl }}
              </a>
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            text
            @click="copyTextToClipboard()">
            Copy to Clipboard
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defaultOrderData, actionButtons } from '@/assets/js/DefaultOrderData'
import { mapActions, mapGetters } from 'vuex'
import ExportToXLSX from 'xlsx'
import OrderProvider from '@/resources/OrderProvider'
import DHLProvider from '@/resources/DHLProvider'
import StoreProvider from '@/resources/StoreProvider'
import ShippingCountries from '@/assets/json/ShippingCountries.json'
import NotSupportWY from '@/assets/json/NotSupportWY.json'
import copy2clipboard from 'copy-to-clipboard'
import BCProvider from '@/resources/BCProvider'

const OrderService = new OrderProvider()
const DHLService = new DHLProvider()
const StoreService = new StoreProvider()
const BCService = new BCProvider()

export default {
  props: {
    orderBackup: {
      type: Object,
      default: null
    },
    hasProductSkus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      btnLoading: {},
      isLoading: false,
      dialogTrackingNumber: false,
      dialogRegenOrder: false,
      regenOrderResult: null,
      trackingNumber: '',
      loadingTrackingNumber: false,
      summaryDialog: false,
      summaryDialogText: {
        discount: 0,
        skus: [],
        shipping: {
          fee: 0,
          method: ''
        },
        net: 0,
        billUrl: '3ldZ8MK'
      }
    }
  },
  computed: {
    ...mapGetters({
      order: 'Order/orderDetail',
      isGotMember: 'Order/isGotMember',
      billUrl: 'Store/linkBill',
      isMainOrder: 'Order/isMainOrder',
      backupOrderDetail: 'Order/backupOrderDetail',
      skusTotalPrice: 'Order/skusTotalPrice',
      codeDiscount: 'Order/codeDiscount',
      shippingFee: 'Order/shippingFee',
      netPrice: 'Order/netPrice'
    }),
    orderDetail () {
      const orderDetail = {
        ...defaultOrderData,
        ...this.order
      }
      this.setTrackingNumber(orderDetail)
      return orderDetail
    },
    orderStatusState () {
      return this.orderDetail.currentState
    },
    orderType () {
      return this.isMainOrder ? 'main' : 'sub'
    },
    buttons () {
      if (this.order.status === 'deleted') {
        return []
      }

      return actionButtons.filter((r) => {
        if (r?.excludeChannel?.some((c) => c === this.orderDetail.channel)) {
          return false
        }

        if (r?.excludeMethod?.some((m) => m === this.orderDetail?.shipping?.method)) {
          return false
        }

        if (r?.oneTimeShipment && this.orderDetail.shipping.refCode) {
          return false
        }

        switch (this.orderDetail.channel) {
          case 'pos':
            return r.orderChannel.some((c) => c === 'pos') && r.otherChannelStatus[this.orderType].some((t) => t === this.orderStatusState)
          case 'web':
            return r.orderChannel.some((c) => c === 'web') && r.otherChannelStatus[this.orderType].some((t) => t === this.orderStatusState)
          default:
            return r.otherChannelStatus[this.orderType].some((t) => t === this.orderStatusState)
        }
      })
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setSelectOrder: 'Order/setSelectOrder',
      setOrderDetail: 'Order/setOrderDetail',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    async switchFunction (functionName, orderDetail) {
      try {
        let orderData = null
        this.setLoadingByFunction(functionName, true)
        switch (functionName) {
          case 'createOrder':
            orderData = await this.createOrder(orderDetail, this.isGotMember, orderDetail.channel)

            break
          case 'openSummaryDialog':
            this.setSummaryDialogText(orderDetail)
            this.summaryDialog = true
            break
          case 'printReceipt':
            // orderData = await this.printReceipt(orderDetail)
            break
          case 'shipKerry':
            orderData = await this.shipKerry(orderDetail.orderId)
            break
          case 'printLabel':
            orderData = await this.printLabel(orderDetail)
            break
          case 'paid':
            orderData = await this.paid(orderDetail)
            break
          case 'readyToShip':
            orderData = await this.readyToShip(orderDetail)
            break
          case 'addTrackingNo.':
            this.dialogTrackingNumber = true
            break
          case 'dhl':
            orderData = await this.dhlProcess(orderDetail)
            break
          case 'dhlExpress':
            orderData = await this.confirmDhlExpress(orderDetail)
            break
          case 'complete':
            orderData = await this.complete(orderDetail)
            break
          case 'voidOrder':
            orderData = await this.voidOrder(orderDetail)
            break
          case 'cancel':
            this.cancelHandle()
            break
          case 'bcSyncSaleInvoice':
            this.bcSyncSaleInvoice(orderDetail)
            break
          case 'save':
            this.saveHandle()
            break
          default:
            console.error(`switchFunction isn't has ${functionName}.`)
            break
        }
        if (orderData?.orderId) {
          this.fetchOrderDetail(orderData.orderId)
          this.cancelEdit()
        }
        this.setLoadingByFunction(functionName, false)
      } catch (error) {
        this.setLoadingByFunction(functionName, false)
        console.error(functionName, error)
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      }
    },
    setLoadingByFunction (label, state) {
      this.isLoading = state
      this.btnLoading[label] = state
    },
    setTrackingNumber (order) {
      this.trackingNumber = order?.shipping?.refCode || ''
    },
    closeDialogRegenOrder () {
      this.dialogRegenOrder = false
      this.$emit('regen-success', this.regenOrderResult)
      this.regenOrderResult = null
    },
    closeDialogTrackingNumber () {
      this.dialogTrackingNumber = false
    },
    sizeSkuFormatter (color) {
      return color.replace('International:', '').toUpperCase()
    },
    copyOrderId (orderId) {
      copy2clipboard(orderId)
      this.setSnackbar({
        value: true,
        message: 'copied to clipboard.',
        type: 'success'
      })
    },
    copyTextToClipboard () {
      let skusText = ''
      this.summaryDialogText.skus.forEach((sku) => {
        // eslint-disable-next-line max-len
        skusText += `${sku.name} : ${this.sizeSkuFormatter(sku.size)},${sku.color.toUpperCase()} (${sku.amount}) x ${this.showFullPriceFormat(sku.price)}\n`
      })

      // eslint-disable-next-line max-len
      const text = `สรุปการสั่งซื้อ\n${skusText}\nส่วนลด ${this.summaryDialogText.discount}\nค่าจัดส่ง (${this.summaryDialogText.shipping.method || ' - '}) ${this.showFullPriceFormat(this.summaryDialogText.shipping.fee)}\nรวม ${this.showFullPriceFormat(Math.ceil(this.summaryDialogText.net))}\n\nตรวจสอบรายละเอียดการสั่ง วิธีการชำระเงิน ยืนยันการชำระและที่อยู่ในการจัดส่งได้ที่นี่เลย ${this.billUrl}/th/${this.summaryDialogText.billUrl}`
      copy2clipboard(text)
      this.setSnackbar({
        value: true,
        message: 'copied to clipboard.',
        type: 'success'
      })
    },
    showFullPriceFormat (number) {
      const num = parseFloat(number)
      let result = 0
      if (num === 0 || num) {
        result = parseFloat(num).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return result
    },
    async fetchOrderDetail (id) {
      try {
        let fetchData = null
        if (this.isMainOrder) {
          const { data } = await OrderService.getOrderById(id)
          fetchData = data
        } else {
          const { data } = await OrderService.getOrderWarehouseById(id)
          fetchData = data
        }
        if (fetchData?.id) {
          this.setSelectOrder(fetchData)
        } else {
          const error = {
            code: 400,
            message: 'Something went wrong.'
          }
          throw error
        }
      } catch (error) {
        console.error(error)
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      }
    },
    opneNewTab (url) {
      window.open(url, '_blank').focus()
    },
    shippingAddressValidate (shippingAddress) {
      const validFields = [
        'firstName',
        'lastName',
        'contactNo',
        'email',
        'address',
        'subDistrict',
        'district',
        'stateProvince',
        'postcode',
        'country'
      ]
      let invalidField = null
      for (const field of validFields) {
        if (!shippingAddress[field]) {
          invalidField = field
          break
        }
      }

      return invalidField
    },
    async createOrder (payload, isGotMember, channel = 'manual') {
      const orderPayload = payload
      if (orderPayload?.skus?.length <= 0 || !isGotMember || !(orderPayload?.shipping?.method)) {
        const error = {
          code: 400,
          message: 'Plaese add product, get member, select shipment.'
        }
        throw error
      }

      const shippingAddressCheck = this.shippingAddressValidate(orderPayload.shippingAddress)
      if (this.shippingAddressCheck) {
        const error = {
          code: 400,
          message: `Plaese complete shipping address, can't find ${shippingAddressCheck}.`
        }

        throw error
      }

      const promotionCode = orderPayload.promotionCode?.id
      ? {
        id: orderPayload.promotionCode?.id || '',
        code: orderPayload.promotionCode?.code || '',
        discountNumber: orderPayload.promotionCode?.discountNumber || 0,
        discountType: orderPayload.promotionCode?.discountType || '',
        maxPrice: orderPayload.promotionCode?.maxPrice || 0,
        minPrice: orderPayload.promotionCode?.minPrice || 0,
        perUser: orderPayload.promotionCode?.perUser || 0,
        dateRange: orderPayload.promotionCode?.dateRange || undefined,
        amount: orderPayload.promotionCode?.amount || 0,
        channel: orderPayload.promotionCode?.channel || '',
        productTagIds: orderPayload.promotionCode?.productTagIds || '',
        productCategoryIds: orderPayload.promotionCode?.productCategoryIds || '',
        note: orderPayload.promotionCode?.note || '',
        campaignType: orderPayload.promotionCode?.campaignType || '',
        status: orderPayload.promotionCode?.status || '',
        discountPrice: orderPayload?.discountPrice || 0,
        discount: orderPayload?.discount || 0
      } : null

      delete orderPayload.promotionCode

      const discount = this.codeDiscount || 0

      const newSkus = []

      Object.keys(orderPayload.subOrders).forEach((key) => {
        const skus = orderPayload.subOrders[key].skus.map((sku) => ({
          ...sku,
          preOrder: sku?.preOrder || false,
          preOrderNote: sku?.preOrderNote || ''
        }))

        newSkus.push(...skus)
        delete orderPayload.subOrders[key].preOrder
      })

      const createData = {
        ...orderPayload,
        promotionCode,
        discount,
        skus: newSkus,
        net: this.netPrice,
        payments: [
          {
            payOrder: 1,
            deadlineDate: orderPayload.expire,
            sumDebtAmount: this.netPrice,
            debtAmount: this.netPrice
          }
        ]
      }

      let created
      if (channel === 'manual') {
        const { data } = await OrderService.createOrderManual(createData)
        created = data
      }

      if (channel === 'celeb') {
        const { data } = await OrderService.createOrderCeleb(createData)
        created = data
      }

      if (channel === 'international_manual') {
        const { data } = await OrderService.createOrderInternational(createData)
        created = data
      }

      this.setSummaryDialogText(created)
      this.summaryDialog = true
      return created
    },
    setSummaryDialogText (createData) {
      this.summaryDialogText = {
        discount: createData.discount || 0,
        net: createData.net,
        shipping: createData.shipping,
        skus: createData.skus,
        billUrl: createData.url
      }
    },
    async closeSummaryDialog () {
      this.summaryDialog = false
    },
    printLabel (payload) {
      this.setPrintList(JSON.stringify([payload.orderId]))
      const routeData = this.$router.resolve({ name: 'OrderPrintLabel' })
      window.open(routeData.href, '_blank')
    },
    setPrintList (payload) {
      window.localStorage.removeItem('print-list')
      window.localStorage.setItem('print-list', payload)
    },
    async shipKerry (orderId) {
      const { data: kerryShipData } = await OrderService.shipKerry({ orderId })
      if (kerryShipData.success) {
        this.setSnackbar({
          value: true,
          message: 'Success',
          type: 'success'
        })
      }

      const { data: orderData } = await OrderService.getOrderWarehouseById(orderId)
      return orderData
    },
    async getDhlPickupId () {
      const { data: { dhlEcommerceSetting: { pickupWarehouses, dhlPrefix, filename } } } = await StoreService.getStoreSetting()
      return { pickupWarehouses, dhlPrefix, filename }
    },
    async dhlProcess (order) {
      try {
        const { pickupWarehouses, dhlPrefix, filename } = await this.getDhlPickupId()
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Creating DHL Ecommerce shipment...'
        })

        const foundpickupId = pickupWarehouses.find((warehouse) => warehouse.id === order.warehouse.id)
        const pickupId = foundpickupId?.pickupId || null
        const orderPrefix = order.orderId.substring(0, 2)
        const orderId = order.orderId.replace('-', '')
        const cutDigit = orderId.substring(2)
        let increaseDigit = 1

        if (order.shipping.refCode) {
          const prefix = order.shipping.refCode.substring(0, 8)

          if (prefix === `${dhlPrefix}${orderPrefix}D`) {
            const postfix = order.shipping.refCode.substring(order.shipping.refCode.length - 2)
            increaseDigit = Number(postfix) + 1
          }
        }

        increaseDigit = increaseDigit.toString().padStart(2, 0)

        const trackNumber = `${orderPrefix}D${cutDigit}${increaseDigit}`
        const address = `${order.shippingAddress.address} ${order.shippingAddress.address2 || ''} ${order.shippingAddress.subDistrict}`
        const address1 = `${address.slice(0, 55)}`
        const address2 = `-${address.slice(55, 110)}`
        const address3 = `-${address.slice(110, 165)}`

        const payload = {
          orderId: order.orderId,
          providerName: 'DHL',
          method: 'pdo',
          refCode: `${dhlPrefix}${trackNumber}`
        }

        const exportData = {
          'เลขบัญชีที่รับสินค้า': pickupId,
          'ช่องทางการขาย': '',
          'รหัสการจัดส่ง': trackNumber,
          'รหัสบริการจัดส่ง': 'PDO',
          'บริษัท': '',
          'ชื่อผู้รับ': `${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`,
          'ที่อยู่บรรทัดที่ 1': address1,
          'ที่อยู่บรรทัดที่ 2': address2,
          'ที่อยู่บรรทัดที่ 3': address3,
          'เขต (อำเภอ)': order.shippingAddress.district,
          'จังหวัด': order.shippingAddress.stateProvince,
          'รหัสไปรษณีย์': order.shippingAddress.postcode,
          'รหัสประเทศปลายทาง': 'TH',
          'หมายเลขโทรศัพท์': order.shippingAddress.contactNo,
          'อีเมล์์': order.shippingAddress.email,
          'น้ำหนักสินค้า (กรัม)': '100',
          'ความยาว (ซม)': '',
          'ความกว้าง (ซม)': '',
          'ความสูง (ซม)': '',
          'สกุลเงิน': 'THB',
          'ยอดรวมมูลค่าสินค้า': '',
          'ประกัน': '',
          'มูลค่าการทำประกัน': '',
          'เก็บเงินปลายทาง': '',
          'มูลค่าการเก็บเงินปลายทาง': '',
          'รายละเอียดการจัดส่ง': '',
          'หมายเหตุ': '',
          'ชื่อบริษัทผู้จัดส่ง': '',
          'ชื่อผู้จัดส่ง': '',
          'ที่อยู่ผู้จัดส่งบรรทัดที่ 1': '',
          'ที่อยู่ผู้จัดส่งบรรทัดที่ 2': '',
          'ที่อยู่ผู้จัดส่งบรรทัดที่ 3': '',
          'เขต (อำเภอ) ผู้จัดส่ง': '',
          'จังหวัดผู้จัดส่ง': '',
          'รหัสไปรษณีย์ผู้จัดส่ง': '',
          'รหัสประเทศปลายทางผู้จัดส่ง': '',
          'หมายเลขโทรศัพท์ผู้จัดส่ง': '',
          'อีเมล์ผู้จัดส่ง': '',
          'รหัสบริการส่งคืนสินค้า': '',
          'ชื่อบริษัทที่ส่งคืน': '',
          'ชื่อที่ส่งคืน': '',
          'ที่อยู่ที่ส่งคืนบรรทัดที่ 1': '',
          'ที่อยู่ที่ส่งคืนบรรทัดที่ 2': '',
          'ที่อยู่ที่ส่งคืนบรรทัดที่ 3': '',
          'เขต (อำเภอ) ที่ส่งคืน': '',
          'จังหวัดที่ส่งคืน': '',
          'รหัสไปรษณีย์ที่ส่งคืน': '',
          'รหัสประเทศปลายทางที่ส่งคืน': '',
          'หมายเลขโทรศัพท์ที่ส่งคืน': '',
          'อีเมล์ที่ส่งคืน': '',
          'บริการ 1': '',
          'บริการ 2': '',
          'กระบวนการ Handover': '',
          'โหมดการส่งคืนของ': '',
          'การอ้างอิงการเรียกเก็บเงิน 1': '',
          'การอ้างอิงการเรียกเก็บเงิน 2': '',
          'IsMult': '',
          'ตัวเลือกการจัดส่ง': '',
          'รหัสชิ้น': '',
          'คำอธิบายรายชิ้น': '',
          'น้ำหนักรายชิ้น': '',
          'การเรียกเก็บเงินปลายทางรายชิ้น': '',
          'มูลค่าการทำประกันรายชิ้น': '',
          'การอ้างอิงการเรียกเก็บเงินรายชิ้น 1': '',
          'การอ้างอิงการเรียกเก็บเงินรายชิ้น 2': ''
        }

        await OrderService.updateTrackingOrderDHL(payload)

        const workSheet = ExportToXLSX.utils.json_to_sheet([exportData])
        const newWorkbook = ExportToXLSX.utils.book_new()
        ExportToXLSX.utils.book_append_sheet(newWorkbook, workSheet, 'Sheet1')
        ExportToXLSX.writeFile(newWorkbook, `${filename}.xlsx`)

        this.setSnackbar({
          value: true,
          message: 'Created shipment successfully.',
          type: 'success'
        })

        const { data } = await OrderService.getOrderWarehouseById(order.orderId)

        return data
      } catch (error) {
        console.error('dhlProcess', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })

        return order
      } finally {
        this.setLoading({ active: false })
      }
    },
    async confirmDhlExpress (order) {
      if (!order.shipping.refCode) {
        return this.dhlExpressProcess(order)
      }

      this.setModal({
        value: true,
        title: 'ส่ง DHL Express',
        message: 'ออเดอร์นี้ มีการส่ง DHL Express ไปแล้ว ต้องการส่งอีกครั้งหรือไม่?',
        confirmText: 'ใช่',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'ไม่ใช่',
        onConfirm: () => this.dhlExpressProcess(order, true)
      })

      return null
    },
    async dhlExpressProcess (order, fetchOrder = false) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Creating shipment...'
        })

        const foundCountry = ShippingCountries.find((c) => c.country.toLowerCase() === order.shippingAddress.country.toLowerCase())

        if (!foundCountry) {
          throw Error('Country or postal code was wrong.')
        }

        const { data } = await DHLService.createShipment({
          orderId: order.orderId,
          receiverCountryCode: foundCountry.code
        })

        if (NotSupportWY.includes(foundCountry.code)) {
          data.documents.forEach((document) => {
            this.downloadPDF(document.content, `${order.orderId}-${document.typeCode}`)
          })
        } else {
          const document = data.documents.find((doc) => doc.typeCode === 'label')

          if (document) {
            this.downloadPDF(document.content, `${order.orderId}-${document.typeCode}`)
          }
        }

        await OrderService.updateTrackingOrder(order.orderId, data.shipmentTrackingNumber)

        this.setSnackbar({
          value: true,
          message: 'Created shipment successfully.',
          type: 'success'
        })

        if (fetchOrder) {
          this.fetchOrderDetail(order.orderId)
          this.cancelEdit()

          return order
        }

        const { data: updatedOrder } = await OrderService.getOrderWarehouseById(order.orderId)

        return updatedOrder
      } catch (error) {
        console.error('dhlExpressProcess', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })

        return order
      } finally {
        this.setLoading({ active: false })
      }
    },
    downloadPDF (pdf, fileName) {
      const linkSource = `data:application/pdf;base64,${pdf}`
      const downloadLink = document.createElement('a')

      downloadLink.href = linkSource
      downloadLink.download = `${fileName}.pdf`
      downloadLink.click()
    },
    async paid (payload) {
      const { data } = await OrderService.updateStatusToPaid(payload.orderId)
      return data
    },
    async readyToShip (payload) {
      const { orderId } = payload
      try {
      const { data } = await OrderService.updateStatusToReadyToShip(orderId)
      if (data?.orderId) {
          this.fetchOrderDetail(data.orderId)
        } else {
          const error = {
            code: 400,
            message: 'Something went wrong'
          }
          throw error
        }
      } catch (error) {
        console.error('readyToShip', error)
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loadingTrackingNumber = false
      }
      return payload
    },
    async addTrackingNo (payload, refCode) {
      try {
        this.loadingTrackingNumber = true
        const { data } = await OrderService.updateTrackingOrder(payload.orderId, refCode)
        if (data?.orderId) {
          this.fetchOrderDetail(data.orderId)
          this.closeDialogTrackingNumber()
        } else {
          const error = {
            code: 400,
            message: 'Something went wrong'
          }
          throw error
        }
      } catch (error) {
        console.error('addTrackingNo', error)
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loadingTrackingNumber = false
      }
    },
    async complete (payload) {
      const { data } = await OrderService.updateStatusToComplete(payload.orderId)
      return data
    },
    async voidOrder (payload) {
      const { data } = await OrderService.updateStatusToComplete(payload.orderId)
      return data
    },
    cancelEdit () {
      this.$emit('cancelEdit')
    },
    async bcSyncSaleInvoice (payload) {
      this.setLoading({
        active: true,
        clickAble: false,
        message: 'POSTING TO BC'
      })

      try {
        const { data } = await BCService.syncSalesOrder(payload.orderId)
        if (data) {
          this.setSnackbar({
            value: true,
            message: `Sync ${payload.orderId} success`,
            type: 'success'
          })
        } else {
          this.setSnackbar({
            value: true,
            message: `Sync ${payload.orderId} failed`,
            type: 'error'
          })
        }
      } catch (error) {
          this.setSnackbar({
            value: true,
            message: error.message,
            type: 'error'
          })
      } finally {
        this.setLoading({
          active: false,
          clickAble: false,
          message: ''
        })
      }
    },
    cancelHandle () {
      this.setOrderDetail({ ...this.backupOrderDetail })
    },
    saveHandle () {
      const orderState = this.backupOrderDetail.currentState
      if (orderState === 'pending' || orderState === 'expired') {
        this.editOrder({ ...this.backupOrderDetail, ...this.orderDetail })
      } else {
        this.regenOrder({ ...this.backupOrderDetail, ...this.orderDetail, voidReason: 'EDIT ORDER' })
      }
    },
    async editOrder (order) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATING ORDER...'
        })
        const { message } = await OrderService.updateOrder(order.orderId, this.mapForRegen(order))
        if (message === 'done') {
          this.setSnackbar({
            value: true,
            message: 'Order has been updated',
            type: 'success'
          })

          this.$emit('close-modal-request')
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async regenOrder (order) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'RE GENERATING ORDER...'
        })
        const mappedOrder = this.mapForRegen(order)
        const { message, data } = await OrderService.regenOrder({
          ...mappedOrder,
          payments: this.makeNewPaymentForRegen(mappedOrder.payments, mappedOrder.expire)
        })

        if (message === 'done') {
          this.dialogRegenOrder = true
          this.regenOrderResult = data
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code: ${error.code}, ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    makeNewPaymentForRegen (_payments, expire) {
      let payments = _payments.sort((a, b) => (a.payOrder - b.payOrder))
      const latestPayment = payments[payments.length - 1]
      const newDebtAmount = this.netPrice - latestPayment.sumDebtAmount
      payments = payments.map((payment) => {
        const temp = JSON.parse(JSON.stringify(payment))

        return { ...temp, sumDebtAmount: this.netPrice }
      })
      payments.push({
        debtAmount: newDebtAmount > 0 ? newDebtAmount : 0,
        payAmount: 0,
        payOrder: payments.length + 1,
        deadlineDate: expire,
        sumDebtAmount: this.netPrice
      })

      return payments
    },
    subOrdersToSkus (subOrders) {
      const skus = []
      for (const subOrder of subOrders) {
        const eachWarehouseSkus = subOrder.skus.map((sku) => ({ ...sku, warehouse: subOrder.warehouse }))
        skus.push(...eachWarehouseSkus)
      }

      return skus
    },
    mapForRegen (order) {
      const skus = this.subOrdersToSkus(order.subOrders)
      const newNetPrice = this.netPrice
      const oldSumDebtAmount = order.payments[order.payments.length - 1].sumDebtAmount
      const newPayments = []
      let newDebtAmount = 0
      order.payments.sort((a, b) => (a.payOrder - b.payOrder)).forEach((r) => {
        if (r.paidDate) {
          newPayments.push(r)
        } else {
          newDebtAmount += r.debtAmount
        }
      })

      if (newDebtAmount > 0) {
        const lastPayOrder = newPayments[newPayments.length - 1]?.payOrder || 0
        newPayments.push({
          payOrder: lastPayOrder + 1,
          deadlineDate: order.expire,
          sumDebtAmount: this.netPrice,
          debtAmount: (newNetPrice - oldSumDebtAmount) + newDebtAmount
        })
      }

      if (newPayments.length === 0) {
        newPayments.push({
          payOrder: 1,
          deadlineDate: order.expire,
          sumDebtAmount: this.netPrice,
          debtAmount: (newNetPrice - oldSumDebtAmount) + newDebtAmount
        })
      }

      const mapped = {
        discount: this.codeDiscount,
        expire: order.expire,
        payments: newPayments,
        voidReason: order.voidReason,
        regenFrom: {
          id: order.id,
          orderId: order.orderId
        },
        shipping: order.shipping,
        skus,
        channel: order.channel,
        member: order.member,
        note: order.note,
        promotionCode: order.promotionCode,
        shippingAddress: order.shippingAddress,
        net: this.netPrice,
        nationality: order?.nationality || null,
        gender: order?.gender || 'N/A',
        brand: order.brand,
        branch: order.branch ?? null,
        pos: order.pos ?? null
      }

      return mapped
    },
    findWarehouse (skuCode, subOrders) {
      const skus = []
      subOrders.forEach((subOrder) => {
        skus.push(...subOrder.skus.map((sku) => sku))
      })

      const found = skus.find((sku) => skuCode === sku.code)
      return found?.warehouse || { id: 0, name: null, code: null }
    }
  }
}
</script>
